import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

function ImgWithOverlay(props) {
  const {
    height,
    minHeight,
    maxHeight,
    backgroundColor,
    backgroundPosition,
    children,
    image
  } = props
  
  return (
    <>
      <div
        className={`w-full left-0 absolute`}
        style={{ 
          height: height,
          minHeight: minHeight,
          maxHeight: maxHeight
        }}
      >

        <BackgroundImage
          fluid={image.node.fluid}
          backgroundColor={`#ccc`}
          style={{
            height: '100%',
            width: '100%',
            backgroundPosition: `${backgroundPosition}`,
            position: 'absolute'
          }}
        />

        <div className="h-full w-full absolute" style={{ backgroundColor }}>
          {children}
        </div>

      </div>

      <div
        style={{
          height: height,
          minHeight: minHeight,
          maxHeight: maxHeight
        }}
      />
    </>
  );
}

export default props => {

return (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          } 
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(edge => edge.node.fluid.originalName === props.filename)

      if (!image) return null

      return <ImgWithOverlay image={image} {...props} />
    }}
  />
)}

ImgWithOverlay.defaultProps = {
  height: 400,
  minHeight: 400,
  maxHeight: 400,
  backgroundColor: null,
  backgroundPosition: "center top",
  image: {}
};

ImgWithOverlay.propTypes = {
  filename: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string,
  backgroundPosition: PropTypes.string,
  image: PropTypes.object,
};
